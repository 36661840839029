#forgot-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#forgot-backButton {
    position: relative;
    display: inline-block;
    right: 4em;
    bottom: 0.1em;
    filter: invert(100%);
}

#forgot-header {
    background-color: #224cbf;
    padding-bottom: 4em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
}

#forgot-forgotPasswordText {
    color: white;
    display: inline-block;
}

#forgot-emailAndSubmit {
    margin-top: 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

#forgot-subtitle {
    text-align: center;
}

#forgot-emailInput {
    width: 400px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

#forgot-submit {
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}