#homeWithLogin-body {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

#homeWithLogin-logo {
    margin-top: 4em;
    text-align: center;
    border-radius: 1em;
}

#homeWithLogin-userInfo {
    display: inline-block;
    color: #1a1818;
    background-color: #eceaea;
    padding: 0 1em 0 1em;
    border: black 3px solid;
}

#homeWithLogin-displayName {
    color: #133da5;
}

#homeWithLogin-loading {
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 20px;
}

#homeWithLogin-suggestions {
    padding: 30px;
    text-align: center;
    background-color: darkcyan;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30%;
    margin-right: 30%;
    border-radius: 30px;
}

#homeWithLogin-navbar {
    text-align: center;
}

#homeWithLogin-navbar ul li {
    display: inline;
}

#homeWithLogin-icons {
    width: 3em;
    height: 3em;
    border-radius: 20px;
    align-content: center;
    margin-left: 3.9em;
    margin-right: 4.5em;
}

#homeWithLogin-links {
    margin-top: -0.5em;
}

#homeWithLogin-menuItem {
    text-align: center;
    color: black;
    margin: 50px 40%;
    padding: 30px;
    background-color: lightgray;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

#homeWithLogin-menuItemIcons {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    left: -44%;
}

#homeWithLogin-menuItemLinks {
    position: absolute;
    text-align: center;
}

#homeWithLogin-companyManagement {
    margin-top: 5em;
}

#homeWithLogin-companyManagementHeader {
    text-align: center;
}

#homeWithLogin-equipmentManagement {
    margin-top: 5em;
}

#homeWithLogin-equipmentManagementHeader {
    text-align: center;
}

a[href] {
    color: black;
}