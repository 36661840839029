#userGroups-body {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#userGroups-header {
    background-color: #133da5;
    padding: 2em 0 0 0;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
}

#userGroups-titleText {
    color: white;
    display: inline-block;
}

#userGroups-backButton {
    position: relative;
    display: inline-block;
    right: 4em;
    padding-bottom: 4em;
    bottom: 0.1em;
    filter: invert(100%);
}

#userGroups-addButtonDiv {
    position: relative;
    display: inline-block;
    height: 5px;
    width: 5px;
    left: 20%;
    top: 0.7em;
}

#userGroups-addButton {
    height: 40px;
    width: 40px;
}

#userGroups-table {
    margin: 2em auto 0 auto;
    border-collapse: collapse;
    width: 600px;
}

.userGroups-tableElement {
    text-align: center;
    border: 1px solid #cccccc;
    height: 3em;
}

.userGroups-tableHeading {
    border: 1px solid #cccccc;
    height: 2em;
    background-color: #5982d3;
}

#userGroups-tableArrowButton {
    background-color: transparent;
    border: none;
}

#userGroups-tableArrow {
    height: 2em;
    width: 2em;
}

#userGroups-dropdown {
    display: inline-block;
}

#userGroups-dropdownMenu {
    display: none;
    position: absolute;
    z-index: 1;
}

.userGroups-dropdownButton {
    background-color: white;
    border-style: solid;
    border-color: black;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    min-width: 120px;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: 12px 16px;
}

.userGroups-dropdownButton:first-child {
    border-bottom: none;
}

.userGroups-dropdownButton:hover {
    background-color: #f1f1f1;
}

#userGroups-dropdown:hover #userGroups-dropdownMenu {
    display: block;
}