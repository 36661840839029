#login-body {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

#login-logo {
    margin-top: 4em;
    border-radius: 1em;
}

#back {
    position: absolute;
    top: 50px;
    left: 30px;
    z-index: 1;
    width: 15px;
    height: 15px;
}

#loginMain {
    display: flex;
}

#signin {
    position: relative;
    text-align: center;
}

#loginTitle, #subtitle {
    position: relative;
    text-align: center;
}

#loginTitle {
    font-weight: bold;
}

.login-input {
    width: 500px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

#forgotPasswordTextDiv {
    width: 500px;
    display: block;
    margin: -1em auto auto auto;
    text-align: right;
}

#login-forgotPassword {
    position: relative;

}

#signin {
    width: 500px;
    margin-top: 15px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #143ca5;
    color: white;
    font-weight: bold;
}

#loginButtons {
    margin: 0 auto;
    text-align: center;
}

#signup {
    color: grey;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: gray
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid gray;
    color: gray;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}