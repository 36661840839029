
#createEquipmentManagement-page {
    text-align: center;
}

#createEquipmentManagement-backButton {
    position: absolute;
    left: calc(50% - 153px);
    filter: invert(100%);
    margin-top: 17px;
}

#createEquipmentManagement-header {
    background-color: #224cbf;
    margin-left: 30%;
    margin-right: 30%;
    padding-bottom: 50px;
    padding-top: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 25px;
}

#createEquipmentManagement-createEquipmentManagementText {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

#createEquipmentManagement-subtitle {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

#createEquipmentManagement-inputBoxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  #createEquipmentManagement-Input {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }



#createEquipmentManagement-Label {
    text-align:left;
}


#createEquipmentManagement-createEquipmentManagementbutton {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}

#createEquipmentManagement-CheckImeiButton {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }

 
  
  
  
  