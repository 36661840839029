#homeWithoutLogin-body {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#homeWithoutLogin-logo {
    border-radius: 1em;
    margin-top: 4em;
}

#homeWithoutLogin-loginSignupButtons {
    margin-top: 1em;
    margin-bottom: 3em;
}

#homeWithoutLogin-buttons {
    display: inline-block;
}

#homeWithoutLogin-button-login {
    height: 35px;
    width: 150px;
    background-color: #224cbf;
    color: white;
    font-size: 18px;
    border-radius: 10px;
    border-color: transparent;
    font-weight: bold;
}

#homeWithoutLogin-button-signup {
    height: 35px;
    width: 150px;
    font-size: 18px;
    border-radius: 10px;
    border-color: transparent;
    font-weight: bold;
}

button:hover {
    cursor: pointer;
}

#homeWithoutLogin-suggestions {
    padding: 30px;
    text-align: center;
    background-color: darkcyan;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30%;
    margin-right: 30%;
    border-radius: 30px;
}

#homeWithoutLogin-navbar {
    text-align: center;
}

#homeWithoutLogin-navbar ul li {
    display: inline;
}

#homeWithoutLogin-icons {
    width: 3em;
    height: 3em;
    border-radius: 20px;
    align-content: center;
    margin-left: 3.9em;
    margin-right: 4.5em;
}

#homeWithoutLogin-links {
    margin-top: -0.5em;
}

ul {
    list-style-type: none;
    margin-top: 50px;
    padding: 0;
    overflow: hidden;
}

li a {
    text-align: center;
    font-weight: bold;
    padding: 50px;
    text-decoration: none;
    color: black;
    background-color: lightblue;
}

li a:hover {
    background-color: whitesmoke;
}

a:visited {
    color: black;
}

#homeWithoutLogin-rent {
    text-align: center;
    color: black;
    margin: 50px 40%;
    padding: 30px;
    background-color: lightgray;
    border-radius: 10px;
}