#notification-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#notification-header {
    background-color: #133da5;
    padding-bottom: 3em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
    position: relative;
}

#notification-backButtonDiv {
    position: relative;
    display: inline-block;
    filter: invert(100%);
    right: 8em;
    top: 2.45em;
}

#notification-titleText {
    color: white;
}

.notification-notificationDiv {
    position: relative;
    margin: 0 37% 1em 37%;
    background-color: #f1f1f1;
    border-radius: 1em;
}

.notification-content {
    padding: 1em 2em 2em 2em;
    text-align: left;
}

.notification-datePosted {
    position: absolute;
    left: 1em;
    bottom: -1em;
    font-size: 14px;
    color: gray;
}

.notification-timePosted {
    position: absolute;
    left: 7em;
    bottom: -1em;
    font-size: 14px;
    color: gray;
}

#notification-loading {
    font-size: 18px;
}