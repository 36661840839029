#createEquipmentGroup-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#createEquipmentGroup-backButton {
    position: absolute;
    left: calc(50% - 153px);
    filter: invert(100%);
    margin-top: 17px;
}

#createEquipmentGroup-header {
    background-color: #224cbf;
    margin-left: 30%;
    margin-right: 30%;
    padding-bottom: 50px;
    padding-top: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 25px;
}

#createEquipmentGroup-createEquipmentGroupText {
    color: white;
}

#createEquipmentGroup-subtitle {
    text-align: center;
}

#createEquipmentGroup-inputBoxes {
    text-align: center;
}

#createEquipmentGroup-Input {
    border-style: double;
    border-radius: 5px;
    width: 500px;
    height: 60px;
    font-size: 15px;
    border-color: #c4c7cc;
}

#createEquipmentGroup-Label {
    text-align:left;
}

#createEquipmentGroup-createEquipmentGroupbutton {
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}