#profile {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#profile-header {
    margin-top: 4em;
    margin-bottom: 3em;
}

#profile-headerImage {
    border-radius: 1em;
}

#profile-userName {
    margin-top: 3em;
}

#profile-totalItems {
    left: calc(50% - 3em);
    margin-bottom: 3em;
}

#profile-totalText {
    display: inline-block;
    margin-right: 1em;
    font-weight: bold;
}

#profile-totalNumber {
    display: inline-block;
    color: #224cbf;
    font-weight: bold;
}

#profile-userPastRentals {
    text-align: center;
}

#profile-profileItem {
    position: relative;
    text-align: center;
    margin-bottom: 2em;
    padding: 0.7em 3em 0 3em;
    background-color: whitesmoke;
    border-radius: 2em;
    margin-left: 35%;
    margin-right: 35%;
}

#profile-profileItemTitle {
    margin-top: 0.5em;
}

#profile-profileItemLink {
    color: blue;
}

#profile-profileItemDescription {
    padding: 2em 0 3em 8em;
    text-align: left;
}

#profile-profileItemImageDiv {
    position: absolute;
    top: 22%;
    left: 2.5em;
}

#profile-profileItemImage {
    width: 6em;
    height: 5em;
    border-radius: 1em;
    object-fit: cover;
    border: 5px solid white;
}

#profile-profileItemUserCreated {
    position: absolute;
    bottom: 0;
    left: 31.5%;
}

#profile-profileItemUserCreatedLink {
    color: blue;
}

#profile-profileItemDateAndTimeCreated {
    position: absolute;
    bottom: 0;
    right: 0;
}