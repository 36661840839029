#toolAlerts-body {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#toolAlerts-header {
    background-color: #133da5;
    padding-bottom: 3em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
    position: relative;
}

#toolAlerts-editableText {
    color: #e32020;
}

#toolAlerts-backButtonDiv {
    position: relative;
    display: inline-block;
    filter: invert(100%);
    right: 12em;
    top: 2.45em;
}

#toolAlerts-titleText {
    color: white;
}

#toolAlerts-addButtonDiv {
    position: relative;
    display: inline-block;
    text-align: center;
}

#toolAlerts-addButton {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 39%;
    right: 5%;
}

#toolAlerts-table {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

.toolAlerts-tableElement {
    text-align: center;
    border: 1px solid #cccccc;
    height: 2.5em;
    padding: 2px 1em;
}

.toolAlerts-tableHeading {
    border: 1px solid #cccccc;
    height: 2.5em;
    background-color: #5982d3;
}

#toolAlerts-tableArrowButton {
    border: none;
    background-color: white;
}

#toolAlerts-tableArrow {
    width: 1.5em;
    height: 1.5em;
}

#toolAlerts-dropdown {
    display: inline-block;
}

#toolAlerts-dropdownMenu {
    display: none;
    position: absolute;
    z-index: 1;
}

#toolAlerts-dropdownMenu .toolAlerts-dropdownButton {
    text-decoration: none;
    display: block;
    padding: 12px 16px;
}

.toolAlerts-dropdownButton {
    background-color: white;
    border-style: solid;
    border-color: black;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    min-width: 120px;
    font-weight: bold;
}

.toolAlerts-dropdownButton:first-child {
    border-bottom: none;
}

.toolAlerts-dropdownButton:hover {
    background-color: #f1f1f1;
}

#toolAlerts-dropdown:hover #toolAlerts-dropdownMenu {
    display: block;
}

#toolAlerts-dropdown:hover {
    background-color: #0b7dda;
}