#manufactureManagement-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#manufactureManagement-header {
    position: relative;
    background-color: #133da5;
    margin: 1em 3% 0 3%;
    padding: 2em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

#manufactureManagement-backButtonDiv {
    position: absolute;
    display: block;
    filter: invert(100%);
    text-align: left;
    top: 60px;
}

#manufactureManagement-titleText {
    color: white;
    font-size: 22px;
}

#manufactureManagement-form {
    position: relative;
    background-color: #f1f1f1;
    margin: 0 3% 0 3%;
    padding: 4em 4em 6em 4em;
}

#manufactureManagement-formName {
    position: absolute;
    left: 60px;
    top: 3em;
    font-weight: bold;
}

#manufactureManagement-searchInput {
    font-size: 17px;
    display: block;
    width: 99.3%;
    height: 50px;
    border-radius: 5px;
    border: black;
    margin-bottom: -3em;
}

#manufactureManagement-searchButton {
    position: relative;
    display: block;
    font-size: 17px;
    color: white;
    width: 25%;
    height: 50px;
    border-radius: 0.7em;
    background-color: #224cbf;
    border-color: transparent;
    font-weight: bold;
    top: 4em;
    margin: 0 auto;
}

#manufactureManagement-createButtonDiv {
    position: relative;
    width: 200px;
    height: 50px;
    margin-top: 1em;
    margin-left: 3%;
}

#manufactureManagement-createButton {
    position: absolute;
    background-color: #224cbf;
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 17px;
    width: 200px;
    height: 50px;
    border-radius: 0.7em;
    border-color: transparent;
}

#manufactureManagement-plusIcon {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    top: 20%;
    right: 5%;
}

#manufactureManagement-table {
    margin: 2em auto 0 auto;
    border-collapse: collapse;
    width: 94%;
}

.manufactureManagement-tableElement {
    text-align: center;
    border: 1px solid #cccccc;
    height: 2em;
}

.manufactureManagement-tableHeading {
    border: 1px solid #cccccc;
    height: 2em;
}

#manufactureManagement-pageNavigation {
    margin: 2em 0;
}

.manufactureManagement-tableButtons {
    position: relative;
    left: 38%;
}

.manufactureManagement-divider {
    float: left;
}

.manufactureManagement-editButton {
    float: left;
    width: 40px;
    height: 50px;
    border: none;
    background-color: white;
    color: #133da5;
}

.manufactureManagement-saveButton {
    float: left;
    width: 40px;
    height: 50px;
    border: none;
    background-color: white;
    color: #133da5;
}

.manufactureManagement-modelButton {
    float: left;
    width: 50px;
    height: 50px;
    border: none;
    background-color: white;
    color: #133da5;
}

#manufactureManagement-prev {
    border: none;
    background-color: white;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
}

#manufactureManagement-next {
    border: none;
    background-color: white;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
}

.manufactureManagement-pageNumber {
    border: none;
    background-color: white;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
}