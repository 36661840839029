#companyProfile-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#companyProfile-backButton {
    position: absolute;
    left: calc(50% - 153px);
    filter: invert(100%);
    margin-top: 17px;
}

#companyProfile-header {
    background-color: #224cbf;
    margin-left: 30%;
    margin-right: 30%;
    padding-bottom: 50px;
    padding-top: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 25px;
}

#companyProfile-companyProfileText {
    color: white;
}

#companyProfile-subtitle {
    text-align: center;
}

#companyProfile-inputBoxes {
    text-align: center;
}

.companyProfile-Input {
    width: 500px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

#companyProfile-Label {
    text-align: left;
}

#companyProfile-companyProfilebutton {
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}