#userManagement-body {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#userManagement-header {
    background-color: #133da5;
    padding: 2em 0 0 0;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
}

#userManagement-titleText {
    color: white;
    display: inline-block;
}

#userManagement-backButton {
    position: relative;
    display: inline-block;
    right: 4em;
    padding-bottom: 4em;
    bottom: 0.1em;
    filter: invert(100%);
}

#userManagement-addButtonDiv {
    position: relative;
    display: inline-block;
    height: 5px;
    width: 5px;
    left: 20%;
    top: 0.7em;
}

#userManagement-addButton {
    height: 40px;
    width: 40px;
}

#userManagement-usersInfoTable {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

.userManagement-usersInfoTableElement {
    text-align: center;
    border: 1px solid #cccccc;
    height: 2.5em;
    padding: 2px 1em;
}

.userManagement-usersInfoTableHeading {
    border: 1px solid #cccccc;
    height: 2.5em;
    background-color: #5982d3;
}

#userManagement-tableArrowButton {
    background-color: transparent;
    border: none;
}

#userManagement-tableArrow {
    width: 1.5em;
    height: 1.5em;
}

#userManagement-dropdown {
    display: inline-block;
}

#userManagement-dropdownMenu {
    display: none;
    position: absolute;
    z-index: 1;
}

.userManagement-dropdownButton {
    background-color: white;
    border-style: solid;
    border-color: black;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    min-width: 120px;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: 12px 16px;
}

.userManagement-dropdownButton:first-child {
    border-bottom: none;
}

.userManagement-dropdownButton:hover {
    background-color: #f1f1f1;
}

#userManagement-dropdown:hover #userManagement-dropdownMenu {
    display: block;
}