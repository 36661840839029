#marketplaceSearch-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#marketplaceSearch-header {
    background-color: #133da5;
    padding-bottom: 4em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
}

#marketplaceSearch-backButton {
    display: inline-block;
    position: relative;
    filter: invert(100%);
    right: 4em;
    bottom: 0.1em;
}

#marketplaceSearch-title {
    color: white;
    display: inline-block;
}

#marketplaceSearch-subtitles {
    color: grey;
}

#marketplaceSearch-inputName, #marketplaceSearch-equipmentType, #marketplaceSearch-site,
#marketplaceSearch-fromDateToDate {
    margin-top: 2em;
}

#marketplaceSearch-fromDate {
    display: inline-block;
    margin-right: 1em;
}

#marketplaceSearch-toDate {
    display: inline-block;
    margin-left: 1em;
}

.marketplaceSearch-input {
    border-style: solid;
    border-radius: 5px;
    width: 400px;
    height: 50px;
    font-size: 15px;
    border-color: #c4c7cc;
    color: #133da5;
}

#marketplaceSearch-fromDateInput, #marketplaceSearch-toDateInput {
    text-align: center;
    color: #133da5;
    width: 182px;
}

#marketplaceSearch-searchButton {
    font-size: 15px;
    color: white;
    width: 19em;
    height: 3em;
    margin-top: 20px;
    border-radius: 0.7em;
    background-color: #224cbf;
    border-color: #c4c7cc;
}

#marketplaceSearch-searchResults {
    margin-top: 4em;
}