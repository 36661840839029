#changeProfile-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#changeProfile-header {
    background-color: #133da5;
    padding-bottom: 3em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
    position: relative;
}

#changeProfile-backButtonDiv {
    position: relative;
    display: inline-block;
    filter: invert(100%);
    right: 8em;
    top: 2.45em;
}

#changeProfile-titleText {
    color: white;
}

.changeProfile-formDivs {
    margin-top: 2em;
}

.changeProfile-formLabels {
    color: gray;
}

.changeProfile-formInputs {
    border-style: solid;
    border-radius: 5px;
    width: 400px;
    height: 50px;
    font-size: 15px;
    border-color: #c4c7cc;
    padding: 0 0 0 5px;
}

#changeProfile-emailInput {
    background-color: #f1f1f1;
}

#changeProfile-genderInput {
    color: #133da5;
}

#changeProfile-button {
    font-size: 16px;
    color: white;
    width: 400px;
    height: 50px;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.7em;
    background-color: #224cbf;
    border-color: #c4c7cc;
    font-weight: bold;
}

#changeProfile-loading {
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 18px;
}

#changeProfile-imageInput {
    margin-top: 1em;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: bold;
}

#changeProfile-profilePicture {
    margin-top: 1em;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #9d9d9d;
}