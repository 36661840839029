#signup-page {
    font-family: Roboto, sans-serif;
    text-align: center;
}

#signup-backButton {
    position: absolute;
    left: calc(50% - 153px);
    filter: invert(100%);
    margin-top: 17px;
}

#signup-header {
    background-color: #224cbf;
    margin-left: 30%;
    margin-right: 30%;
    padding-bottom: 50px;
    padding-top: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 25px;
}

#signup-signupText {
    color: white;
}

#signup-subtitle {
    text-align: center;
}

#signup-inputBoxes {
    display: inline-block;
}

#signup-InputText {
    border-style: double;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    font-size: 15px;
    border-color: #c4c7cc;
}

#signup-InputPassword {
    border-style: double;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    font-size: 15px;
    border-color: #c4c7cc;
}

#signup-InputConfirmPassword {
    border-style: double;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    font-size: 15px;
    border-color: #c4c7cc;
}

#signup-InputEmail {
    border-style: double;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    font-size: 15px;
    border-color: #c4c7cc;
}

.signup-inputs {
    width: 500px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

#tacbox {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    border: 3px solid #ddd;
    background-color: #eee;
    max-width: 500px;
}

#signup-signupbutton {
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}

#signup-facebook {
    position: relative;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #3b5998;
    color: white;
    font-weight: bold;
}

#signup-google {
    position: relative;
    padding: 12px 20px;
    left: 5px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #de5246;
    color: white;
    font-weight: bold;
}

#checkbox-error {
    font-size: 14px;
    margin: 15px 0;
    display: inline-block;
    color: #ff0000;
}