#marketplaceAddItem-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#marketplaceAddItem-backButton {
    position: absolute;
    left: calc(50% - 153px);
    filter: invert(100%);
    margin-top: 17px;
}

#marketplaceAddItem-header {
    background-color: #224cbf;
    margin-left: 30%;
    margin-right: 30%;
    padding-bottom: 50px;
    padding-top: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 25px;
}

#marketplaceAddItem-marketplaceAddItemText {
    color: white;
}

#marketplaceAddItem-subtitle {
    text-align: center;
}

#marketplaceAddItem-inputBoxes {
    text-align: center;
}

.marketplaceAddItem-inputs {
    width: 500px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

#marketplaceAddItem-LabelName {
    text-align:left;
}

#marketplaceAddItem-marketplaceAddItembutton {
    font-size: 15px;
    color: white;
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #224cbf;
    border-color: #c4c7cc;
}

#marketplaceAddItem-addImages {
    margin-top: 2em;
    margin-bottom: 0.5em;
}