#marketplace-body {
    font-family: Roboto, sans-serif;
}

#marketplace-header {
    margin-top: 4em;
    margin-bottom: 3em;
    text-align: center;
}

#marketplace-homeIcon {
    position: absolute;
    z-index: auto;
}

#marketplace-SCEMLogo {
    border-radius: 1em;
}

#marketplace-searchButton {
    position: absolute;
    z-index: auto;
}

#marketplace-marketplaceItems {
    text-align: center;
}

#marketplace-marketplaceItem {
    position: relative;
    text-align: center;
    margin-bottom: 2em;
    padding: 0.7em 3em 0 3em;
    background-color: whitesmoke;
    border-radius: 2em;
    margin-left: 35%;
    margin-right: 35%;
}

#marketplace-marketplaceItemTitle {
    margin-top: 0.5em;
}

#marketplace-marketplaceItemLink {
    color: blue;
}

#marketplace-marketplaceItemDescription {
    padding: 2em 0 3em 8em;
    text-align: left;
}

#marketplace-marketplaceItemImageDiv {
    position: absolute;
    top: 22%;
    left: 2.5em;
}

#marketplace-marketplaceItemImage {
    width: 6em;
    height: 5em;
    border-radius: 1em;
    object-fit: cover;
    border: 5px solid white;
}

#marketplace-marketplaceItemUserCreated {
    position: absolute;
    bottom: 0;
    left: 31.5%;
}

#marketplace-marketplaceItemUserCreatedLink {
    color: blue;
}

#marketplace-marketplaceItemDateAndTimeCreated {
    position: absolute;
    bottom: 0;
    right: 0;
}