#siteAndTrackingLink-page {
    text-align: center;
    font-family: Roboto, sans-serif;
}

#siteAndTrackingLink-header {
    background-color: #133da5;
    padding-bottom: 3em;
    padding-top: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    margin: 2em 30% 2em;
    position: relative;
}

#siteAndTrackingLink-backButtonDiv {
    position: relative;
    display: inline-block;
    filter: invert(100%);
    right: 8em;
    top: 2.45em;
}

#siteAndTrackingLink-titleText {
    color: white;
}

#siteAndTrackingLink-addButton {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 39%;
    right: 5%;
}

#siteAndTrackingLink-table {
    margin: 3em auto 0 auto;
    border-collapse: collapse;
}

.siteAndTrackingLink-tableElement {
    text-align: center;
    border: 1px solid #cccccc;
    height: 2.5em;
    padding: 2px 1em;
}

.siteAndTrackingLink-tableHeading {
    border: 1px solid #cccccc;
    height: 2.5em;
    background-color: #5982d3;
}

#siteAndTrackingLink-tableArrowButton {
    border: none;
    background-color: white;
}

#siteAndTrackingLink-tableArrow {
    width: 1.5em;
    height: 1.5em;
}

#siteAndTrackingLink-dropdown {
    display: inline-block;
}

#siteAndTrackingLink-dropdownMenu {
    display: none;
    position: absolute;
    z-index: 1;
}

.siteAndTrackingLink-dropdownButton {
    background-color: white;
    border-style: solid;
    border-color: black;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    min-width: 120px;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: 12px 16px;
}

.siteAndTrackingLink-dropdownButton:first-child {
    border-bottom: none;
}

.siteAndTrackingLink-dropdownButton:hover {
    background-color: #f1f1f1;
}

#siteAndTrackingLink-dropdown:hover #siteAndTrackingLink-dropdownMenu {
    display: block;
}

#siteAndTrackingLink-dropdown:hover {
    background-color: #0b7dda;
}