/* MarketplaceItem.css */

.container {
    margin: 20px;
    padding: 0 20px 20px 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
}

.item-details {
    margin-bottom: 20px;
}

.item-details h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.item-details p {
    margin-bottom: 5px;
    text-align: left;
}

.reviews {
    margin-bottom: 20px;
    text-align: center;
}

.reviews h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.review-list {
    list-style-type: none;
    padding: 0;
}

.review-list li {
    margin-bottom: 5px;
}

.review-form {
    display: flex;
    margin-top: 10px;
}

.review-input {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 500px;
    padding: 12px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.review-submit {
    padding: 10px 20px;
    background-color: #224cbf;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.loading {
    font-style: italic;
}

.star {
    font-size: 20px;
    color: gold;
}


.review-item {
    margin-bottom: 10px;
}

.review-box {
    border: 1px solid #ccc;
    background-color: #ededed;
    padding: 10px;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.blue-box {
    background-color: #224cbf;
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blue-box h2 {
    color: white;
    font-size: 24px;
    margin: 0;
}

.price {
    color: red;
}

.item-image {
    display: flex;
    justify-content: center;
}

.item-image img {
    width: 100%;
    max-width: 600px; /* Adjust the value as needed */
    height: auto;
}

.rent-submit {
    padding: 10px 50px;
    background-color: #224cbf;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}